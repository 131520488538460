<template>
  <VueSidePanel v-model="showPanel" side="right" width="450px" hide-close-btn :no-close="true">
    <template #header>
      <div class="panel-header">
        <label class="panel-header_label">Filtros de sucursales</label>
        <button type="button" class="d-none d-lg-block panel-header_button clean-button btn btn-secondary" @click.prevent="cleanFilters()">Limpiar</button>
        <button type="button" class="d-none d-lg-block panel-header_button apply-button btn btn-success" @click.prevent="applyFilters()">Aplicar</button>
      </div>
    </template>
    <template #default>
      <div class="panel-content">
        <div class="category form-check" v-for="category in categoriesOptions" :key="category">
          <input class="form-check-input" @click="handleCategorySelect(category?.slug, $event)" type="checkbox"
                 :disabled="categoriesToApply?.length === 1 && categoriesToApply?.includes(category?.slug)"
                 :checked="categoriesToApply.includes(category.slug) || category.slug === 'todas-las-sucursales' && categoriesToApply.length === categories.length"
                 :id="category.title">
          <label class="category-title form-check-label" :for="category.title">
            <span v-if="category?.image">
              <img class="category-image" :src="cxfHost + '/public-assets' + category.image">
            </span>
            {{ category.title }}
          </label>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-sm-block d-md-block d-lg-none">
        <div class="panel-footer">
          <button type="button" class="panel-header_button clean-button btn btn-secondary" @click.prevent="cleanFilters()">Limpiar</button>
          <button type="button" class="panel-header_button apply-button btn btn-success" @click.prevent="applyFilters()">Aplicar</button>
        </div>
      </div>
    </template>
  </VueSidePanel>
</template>

<script>
import { VueSidePanel } from 'vue3-side-panel';
import 'vue3-side-panel/dist/vue3-side-panel.css';

export default {
  inject: ['cxfHost'],
  props: {
    showPanel: { type: Boolean, default: false },
    categories: { type: Array, required: true },
    selectedCategories: { type: Array, required: true }
  },
  emits: ["applyCategories"],
  components: { VueSidePanel },
  data() {
    return {
      categoriesOptions: [],
      categoriesToApply: [],
      additionalCategories: [
        {
          "title": "Sucursales favoritas",
          "image": null,
          "displayAsMain": false,
          "hideAt": null,
          "slug": "sucursales-favoritas"
        },
        {
          "title": "Todas las sucursales",
          "image": null,
          "displayAsMain": false,
          "hideAt": null,
          "slug": "todas-las-sucursales"
        }
      ]
    }
  },
  mounted() {
    this.categoriesToApply = [...this.selectedCategories];
    this.categoriesOptions = [...this.additionalCategories, ...this.categories]
  },
  methods: {
    handleCategorySelect(category, event) {
      const isChecked = event.target.checked
      if (category === 'todas-las-sucursales') {
        if (isChecked) {
          this.categoriesToApply = [...this.categories.map(cat => cat.slug)]
          return;
        } else {
          this.categoriesToApply = ['banco-azteca'];
          return;
        }
      }
      if (this.categoriesToApply.includes(category)) {
        this.categoriesToApply = this.categoriesToApply.filter((cat) => cat !== category)
      } else {
        this.categoriesToApply.push(category)
      }
    },
    applyFilters() {
      this.$emit('applyCategories', this.categoriesToApply)
    },
    cleanFilters() {
      this.categoriesToApply = ['banco-azteca']
      this.$emit('applyCategories', this.categoriesToApply)
    },
  }
}
</script>

<style>
.panel-header {
  align-items: center;
  background: #efefef;
  display: flex;
  gap: 1em;
  height: 70px;
  padding: 1em;
}
.panel-header_label {
  flex: 2;
}
.panel-header_button {
  flex: 1;
  min-width: 100px;
}
.panel-header_label {
  color: black;
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
}
.panel-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 1em;
}
.category {
  display: flex;
  gap: 2em;
}
.category-title {
  color: #A4A4A4;
  font-size: 16px;
  font-style: normal;
}
.category-image {
  height: 32px;
  margin-right: 1em;
  width: 32px;
}
.panel-footer {
  display: flex;
  gap: 1em;
  padding: 1em 1em 1em 1em;
}
.clean-button {
  background: #efefef;
  border-color: #6c757d;
  color: #6c757d;
}
.apply-button {
  background: #43b02a;
}
</style>